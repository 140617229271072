import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import "./nartaBlocksOfText.scss";
const nartaBlocksOfText = props => {
  
  return (
    <div className="container margin_custom custom_row_block">
      <Placeholder name="row-block-placeholder" rendering={props.rendering} />
    </div>
  );
};

export default nartaBlocksOfText;
