import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import ReactComponent from "../ReactComponent";

// if using customSearch, please rename to search (search:customSearch)

// PROPS :
// - onItemsChange : Callback to update the items in the parent component. the setState method must be implemented in the parent and passed to this prop
// - query : provide a string replacing variables by ## followed by the query attribute name.
// ex: first: ##first, after: "##after", conditions: [ ##fieldsEqual ]
// range: [{name:"saleprice_tf", from:"##rangeFrom", to: "##rangeTo"}]
// ..., ##sortBy
// - itemsPerPage : number of items to retrieve for each page (corresponds to first query attribute). Default: 3
// - showIndexCount : boolean to show or hide indexCount part. ex: items 1 - 10 of 100
// - solrFields : Array of strings corresponding to the fieldsEqual of the query. ex: '{name: "categories_sm", value: "'+variable+'"},'
// if the solrfield is static in the query then there is no need to put it in the prop but rather directly in the query
// - range : range filter for customSearch, array of two
// - sort : array of 2 string : index name and either ASC or DESC
// - keyword : string
class nartaQueryBuilder extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      itemdata: [],
      initialize: false,
    };
  }

  componentDidMount() {
    this.setState({ initialize: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.itemdata !== prevState.itemdata) {
      this.props.onItemsChange(this.state.itemdata);
    }
    if (
      this.state.initialize !== prevState.initialize ||
      this.props.solrFields !== prevProps.solrFields ||
      this.props.itemsPerPage !== prevProps.itemsPerPage ||
      this.props.sort !== prevProps.sort ||
      this.props.keyword !== prevProps.keyword
    ) {
      let postsPerPage =
        this.props.itemsPerPage !== undefined ? this.props.itemsPerPage : 3;
      if (
        postsPerPage === undefined ||
        postsPerPage === null ||
        isNaN(postsPerPage) ||
        postsPerPage === 0 ||
        postsPerPage < 0
      )
        postsPerPage = 3;

      let solrFields =
        this.props.solrFields !== undefined ? this.props.solrFields : [];
      let sort = this.props.sort !== undefined ? this.props.sort : [];
      let keyword = this.props.keyword !== undefined ? this.props.keyword : "";

      var API_ENDPOINT = this.graphQLEndpoint + "&";
      var QUERY = this.props.query;

      while (QUERY.includes("##fieldsEqual")) {
        if (solrFields.length !== 0)
          QUERY = QUERY.replace("##fieldsEqual", solrFields.join(""));
        else QUERY = QUERY.replace("##fieldsEqual", solrFields);
      }
      while (QUERY.includes("##keyword"))
        QUERY = QUERY.replace("##keyword", keyword);
      // while (QUERY.includes("##sortBy")) {
      //   if (sort.length !== 0) QUERY = QUERY.replace("##sortBy", 'sortBy: {name:"'+sort[0]+'", order:"'+sort[1]+'"},');
      //   else QUERY = QUERY.replace("##sortBy", '');
      // }

      var postQuery = QUERY.substring(QUERY.indexOf("=") + 1);
      var req = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: postQuery,
        }),
      };
      fetch(API_ENDPOINT, req)
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              itemdata: result,
            });
          },

          (error) => {
            this.setState({
              isLoaded: true,
              error,
            });
          }
        );
    }
  }
  render() {
    return null;
  }
}

export default withSitecoreContext()(nartaQueryBuilder);
