import React from "react";
import { Text, Image, RichText, Link } from "@sitecore-jss/sitecore-jss-react";
import { NavLink } from "react-router-dom";
import { getRelativePaths } from "../../utils/nartaUtils.js";
import "./nartaFeaturedPosts.scss";
const nartaFeaturedPosts = props => {
  
  const { FeaturedPostsContentList, designVariant } = props.fields;
  return (
    <div className={`${designVariant && designVariant.value}`}>
      {props.fields.Title &&
        props.fields.Title.value !== "" &&
        props.fields.Text &&
        props.fields.Text.value !== "" && (
          <div className="row featured_posts_custom_heading">
            <div className="col-md-12">
              <Text field={props.fields.Title} tag="h1" />
              <blockquote>
                <Text field={props.fields.Text} tag="p" />
              </blockquote>
            </div>
          </div>
        )}

      <div className="row box-1 featured_posts_custom">
        {FeaturedPostsContentList &&
          FeaturedPostsContentList.map((listItem, index) => (
            <div className="col-sm-6 home-gamme__content-wrapper" key={index}>
              <div className="home-gamme__inner">
                <img src={listItem.fields.Icon && listItem.fields.Icon.value.src} />
                <div className="desc-cn">
                  <Text
                    field={listItem.fields.title}
                    tag="h3"
                    className="helv_lt"
                  />
                  <RichText
                    field={listItem.fields.content}
                    tag="p"
                    className="helv_lt"
                  />
                </div>
                <NavLink
                  to={getRelativePaths(listItem.fields.PostLink.value.href)}
                  className="btn btn_oro helv_md">
                  {listItem.fields.PostLink.value.text}
                </NavLink>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default nartaFeaturedPosts;
