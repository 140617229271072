import React from "react";
import { DotLoader } from "react-spinners";
import { css } from "@emotion/core";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import "./FilterCategories.scss";
import ReactComponent from "../../ReactComponent";
import { handleFilterClick } from "../Tagging";
const override = css`
  margin: auto;
`;

class FilterCategories extends ReactComponent {
  constructor(props) {
    
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      itemdata: [],
      items: [],
      keyword: "",
      categoryItems: "",
      categoryNames: ""
    };
    this.handleCategoriesChange = this.handleCategoriesChange.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("filterCategories")) {
      let sessionItems = sessionStorage.getItem("filterCategories");
      let getSessionItems = sessionItems !== null ? sessionItems : "";

      if (getSessionItems !== "") {
        this.setState({
          categoryItems: getSessionItems
        });
      }
    }

    if (sessionStorage.getItem("filterCategoriesName")) {
      let sessionItems = sessionStorage.getItem("filterCategoriesName");
      let getSessionItems = sessionItems !== null ? sessionItems : "";

      if (getSessionItems !== "") {
        this.setState({
          categoryNames: getSessionItems
        });
      }
    }

    const url = window.location.href;

    if (this.props.fields.filterOn) {
      let criteria = this.props.fields.filterOn.id.replace(/-/g, "");

      var INDEX = this.indexName;
      var API_ENDPOINT = this.graphQLEndpoint + '&' ;
      var SITE_ROOTPATH = '/sitecore/content/'+this.appName; 

      var QUERY =
        'query={ filters:search( index:"' +
        INDEX +
        '" fieldsEqual: [ {name: "_parent", value: "' +
        criteria +
        '"} ], rootItem: "'+SITE_ROOTPATH+'") { results { items { item { id name ...on NartaProductCategoryTemplate { title { value } } } } } } criteria:search( index:"' +
        INDEX +
        '" fieldsEqual: [ {name: "_group", value: "' +
        criteria +
        '"} ], rootItem: "'+SITE_ROOTPATH+'") { results { items { item { id name template { name } ...on NartafolderproductCategory { displayName title { value }   } } } } } }';

      fetch(API_ENDPOINT + QUERY)
        .then(res => res.json())
        .then(
          result => {
            this.setState({
              isLoaded: true,
              itemdata: result
            });
          },
          error => {
            this.setState({
              isLoaded: true,
              error
            });
          }
        );
    }
  }
  handleCategoriesChange(event) {
    let dataOfCategory = event.target.value.split("__");
    let groupIdValue = dataOfCategory[0];
    let categoryIdValue =
    dataOfCategory[1] !== "default" ? dataOfCategory[1] : "";
    let categoryNameValue =
    dataOfCategory[2] !== "default" ? dataOfCategory[2] : "";
    
    //GTM
    handleFilterClick(event.target.getAttribute("data-filter-name"), dataOfCategory[2])

    let sessionCategories = sessionStorage.getItem("filterCategories");
    let sessionCategoriesName = sessionStorage.getItem("filterCategoriesName");
    let getSessionItems = sessionCategories !== null ? sessionCategories : "";
    let getSessionItemNames =
      sessionCategoriesName !== null ? sessionCategoriesName : "";
    
    if (getSessionItems) {
      let checkCategoryArr = JSON.parse(getSessionItems);
      let checkCategoryNamesArr = JSON.parse(getSessionItemNames);

      let listOfCategory = JSON.parse(getSessionItems);
      let listOfCategoryNames = JSON.parse(getSessionItemNames);

      let categoryArrJson = {};
      let categoryArrNamesJson = {};
      for (var categoryarr1 of checkCategoryArr) {
        if (categoryarr1.groupId != groupIdValue) {
          categoryArrJson.groupId = groupIdValue;
          categoryArrJson.categories = {
            categoryId: categoryIdValue.toLowerCase()
          };

          categoryArrNamesJson.groupId = groupIdValue;
          categoryArrNamesJson.categoriesNames = {
            categoryName: categoryNameValue
          };

          checkCategoryArr.push(categoryArrJson);

          sessionStorage.setItem(
            "filterCategories",
            JSON.stringify(checkCategoryArr)
          );

          let newObject2 = [];

          let newCategoryArrNames = listOfCategoryNames;
          newObject2.push(newCategoryArrNames);
          newObject2.push(categoryArrNamesJson);

          sessionStorage.setItem(
            "filterCategoriesName",
            JSON.stringify(newObject2)
          );
        } else {
          
          var indexC = listOfCategory.findIndex(c => c.groupId == groupIdValue);
          
          if (indexC !== -1 ) {
            listOfCategory.splice(indexC, 1);
            
              let newobj = {};
              newobj.groupId = groupIdValue;
              newobj.categories = { categoryId: categoryIdValue.toLowerCase() };

              listOfCategory.push(newobj);
              
              sessionStorage.setItem(
                "filterCategories",
                JSON.stringify(listOfCategory)
              );
          } else {
            let newobj = {};
            newobj.groupId = groupIdValue;
            newobj.categories = { categoryId: categoryIdValue.toLowerCase() };

            listOfCategory.push(newobj);
            
            sessionStorage.setItem(
              "filterCategories",
              JSON.stringify(listOfCategory)
            );
          }
        }
      }

      for (var categoryarr2 of checkCategoryNamesArr) {
        if (categoryarr2.groupId != groupIdValue) {
          categoryArrNamesJson.groupId = groupIdValue;
          categoryArrNamesJson.categoriesNames = {
            categoryName: categoryNameValue
          };

          checkCategoryNamesArr.push(categoryArrNamesJson);

          sessionStorage.setItem(
            "filterCategoriesName",
            JSON.stringify(checkCategoryNamesArr)
          );
        } else {
          var indexC = listOfCategoryNames.findIndex(
            c => c.groupId == groupIdValue
          );
          if (indexC !== -1) {
            listOfCategoryNames.splice(indexC, 1);
            
            let newobj = {};
            newobj.groupId = groupIdValue;
            newobj.categoriesNames = { categoryName: categoryNameValue };

            listOfCategoryNames.push(newobj);

            sessionStorage.setItem(
              "filterCategoriesName",
              JSON.stringify(listOfCategoryNames)
            );
          } else {
            let newobj = {};
            newobj.groupId = groupIdValue;
            newobj.categoriesNames = { categoryName: categoryNameValue };

            listOfCategoryNames.push(newobj);

            sessionStorage.setItem(
              "filterCategoriesName",
              JSON.stringify(listOfCategoryNames)
            );
          }
        }
      }
      
    } else{
      let newObject1 = [];
      let newObject2 = [];
      let categoryArrJson = {};
      let categoryArrNamesJson = {};

      categoryArrJson.groupId = groupIdValue;
      categoryArrJson.categories = {
        categoryId: categoryIdValue.toLowerCase()
      };

      categoryArrNamesJson.groupId = groupIdValue;
      categoryArrNamesJson.categoriesNames = {
        categoryName: categoryNameValue
      };

      newObject1.push(categoryArrJson);
      newObject2.push(categoryArrNamesJson);
      if(newObject1.length > 0 && newObject2.length > 0){
        
        sessionStorage.setItem("filterCategories", JSON.stringify(newObject1));
        sessionStorage.setItem(
          "filterCategoriesName",
          JSON.stringify(newObject2)
        );
      }
      
    }
    let getSessionUpdated = JSON.parse(
      sessionStorage.getItem("filterCategories")
    );

    let array1 = [];
    let array2 = [];
    for (var sessioncat of getSessionUpdated) {
      array1.push(sessioncat.categories.categoryId);
    }

    let getSessionCategoryNamesUpdated = JSON.parse(
      sessionStorage.getItem("filterCategoriesName")
    );

    for (var sessioncatname of getSessionCategoryNamesUpdated) {
      array2.push(sessioncatname.categoriesNames.categoryName);
    }

    this.props.handleUpdateOnCategory(array1, array2);
    let isPresent = false;
    this.props.handleShowProducts(true);

    for (var category of this.state.items) {
      if (category.item.title.value === categoryNameValue) {
        isPresent = true;
      }
    }
    if (isPresent) {
      this.setState(state => {
        const items = state.items.filter(
          category => categoryNameValue !== category.item.title.value
        );
        return {
          items
        };
      });
    }
    
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.items !== this.state.items &&
      this.state.itemdata.data !== undefined
    ) {
      this.props.onFiltersChange(
        {
          items: this.state.items,
          template: this.state.itemdata.data.criteria.results.items[0].item
            .template.name
        },
        this.state.keyword
      );
    }
    if (prevProps.resetFilters !== this.props.resetFilters) {
      this.setState({
        items: []
      });
    }
  }

  render() {
    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;
    const { error, isLoaded, itemdata } = this.state;
    if (!this.props.fields.filterOn) return "";
    if (!isLoaded)
      return (
        <div className="widget commerce widget_product_categories">
          <div className="sweet-loading">
            <DotLoader
              css={override}
              sizeUnit={"px"}
              size={50}
              color={"#0a4f91"}
              loading={!this.state.isLoaded}
            />
          </div>
        </div>
      );
    if (error) return `Error! ${error.message}`;
    if (!itemdata || !itemdata.data) return `Empty results`;

    var categories = [];

    for (var facet of this.props.facets) {
      if (
        itemdata.data.criteria.results.items[0].item.template.name ===
          "nartafolder-productCategory" &&
        facet.name === "categoryrange"
      ) {
        categories = facet.values;
      }
    }

    let items = [];
    let facetIn = false;

    let groupId = this.state.itemdata.data.criteria.results.items[0].item.id.toLowerCase();
    items.push(
      <option key={groupId} value={`${groupId}__default__default`}>
        Sélectionnez...
      </option>
    );
    for (let category of itemdata.data.filters.results.items) {
      items.push(
        <option
          key={category.item.id}
          value={`${groupId}__${category.item.id}__${category.item.title.value}`}>
          {category.item.title.value}
        </option>
      );
    }

    return (
      <div
        className={`widget commerce widget_product_categories ${designVariant}`}>
        <h4 className="widget-title">
          {this.state.itemdata.data.criteria.results.items[0].item.title.value}{" "}
          :
        </h4>
        <select
          data-filter-name={this.state.itemdata.data.criteria.results.items[0].item.title.value}
          className="product-categories-select"
          onChange={this.handleCategoriesChange}>
          {items}
        </select>
      </div>
    );
  }
}

export default withSitecoreContext()(FilterCategories);
