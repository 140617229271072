import React from 'react';
import { Image, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';


class nartaPostContent extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false
      
    };
  }

  render() {

    const postContent = this.props.sitecoreContext.route.fields;
    

    let postNav = true;
    if (postContent.PostPrevious == null && postContent.PostNext == null) {
      postNav = false;
    }

    return (
      <>
        {postContent &&
          <>
            <div className={`single`}>
              <div className="hentry">
                <div className="hentry-wrap">
                  <div className="entry-featured">
                    <img src={postContent.PostThumbnailImageBig && postContent.PostThumbnailImageBig.value.src}  />
                  </div>
                  <div className="entry-info">
                    <div className="entry-header">
                      <Text field={postContent.PostTitle} tag="h1" className="entry-title" />
                    </div>
                    <div className="entry-content">
                      <RichText field={postContent.PostContent} />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </>
    );
  }

};

export default withSitecoreContext()(nartaPostContent);
