import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ParentRoute from '../../utils/ParentRoute/index.js';


const nartaBreadcrumbs = (props) => {
  return(
    <div className="container">
      <div className="row display__bread">
        <div className="col-md-12 pt-0 pb-0 helv_md">
          <ParentRoute CurrentId={props.sitecoreContext.itemId} CurrentItemName={props.sitecoreContext.route.displayName} />
        </div>
      </div>
    </div>
      
    );
}
export default withSitecoreContext()(nartaBreadcrumbs);
