import React from 'react';
import { Text,withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ContactForm = (props) => (
  <div>
    <Text field={props.fields.contactUsTitle} />
    <iframe id="WebformLOreal" width="100%" height="1400px" frameborder="0" scrolling="no" src="https://loreal-consumer1.secure.force.com/Webform?Brand=narta&Country=france"></iframe>
  </div>
);

export default withSitecoreContext()(ContactForm);
