import React from 'react';
import context from "./utils/Context";
import packageJson from '../package.json';
import configFile from '../src/temp/config';
import common from './utils/Common';


class ReactComponent extends React.Component{

    indexName;
    appName;
    graphQLEndpoint;
    constructor(props){
        super(props);
        this.indexName = context.getIndexName(props);
        this.appName = packageJson.config.appName;
        this.graphQLEndpoint = configFile.graphQLEndpoint;
    }

    activeRatingStatusId() {
        return common.getActiveRatingStatusId(this.props);
    }

}

export default ReactComponent;