import React from "react";
import {
  RichText,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";

class nartaRichTextComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;
    return (
      <div className={`row ${designVariant}`} id={this.props.fields.blockAnchorID && this.props.fields.blockAnchorID.value}>
        <div className="col-md-12 text-center all-articles__custom richtext_custom">
          <RichText
            className="all-articles__content"
            field={this.props.fields.Text}
          />
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(nartaRichTextComponent);
