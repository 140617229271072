import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import "./nartaProductLayoutWrapper.scss";
class nartaProductLayoutWrapper extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      items: []
    };
    this.onProductChange = this.onProductChange.bind(this);
  }

  onProductChange(items) {
    this.setState({
      items: items
    });
  }

  render() {
    return (
      <div className="container single-structure">
        <div className="row">
          <div className="col-sm-12">
            <div className="main-content">
              <div className="style-2 product">
                <Placeholder
                  name="main-content"
                  rendering={this.props.rendering}
                  product={this.state.items}
                  onProductChange={this.onProductChange}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="main-sidebar no-border">
              <Placeholder
                name="sidebar-right"
                rendering={this.props.rendering}
                product={this.state.items}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default nartaProductLayoutWrapper;
