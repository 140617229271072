import React from "react";
import {
  Text,
  RichText,
  withSitecoreContext
} from "@sitecore-jss/sitecore-jss-react";
import "./nartaProductCharacteristics.scss";
import Translate from '../../utils/TranslationRoute';

class nartaProductCharacteristics extends React.Component {  
  render() {
    return (
      <div className="tabbable commerce-tabs single-commerce-tabs">
        <div className="tab-content">
          
                <div
                  id={`tab-0`}
                  className="single-commerce-tabs__content"
                  >
                  <div className="product-description">
                    <h3 className="single-commerce-tabs__title">
                      <Translate TranslationKey="product-description" />
                    </h3>
                    <div className="single-commerce-tabs__desc">
                      <RichText
                        field={
                          this.props.sitecoreContext.route.fields
                            .productLongDescription
                        }
                      />
                    </div>
                  </div>
                  <div className="ldi-decryptee">
                    <h3 className="single-commerce-tabs__title">
                      <Translate TranslationKey="product-ldi-decryptee" />
                    </h3>
                    <div className="single-commerce-tabs__desc">
                      <RichText
                        field={
                          this.props.sitecoreContext.route.fields
                            .productIngredients
                        }
                      />
                    </div>
                  </div>
                </div>
              
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(nartaProductCharacteristics)