import React from 'react';

let firstPropsComment;

class nartaRatingReviewsFilter extends React.Component {

    componentDidMount() {
        firstPropsComment = this.props.comments;
        this.props.onFilterChange(firstPropsComment);
    }
    
    render() {
        return(
            <React.Fragment>
            </React.Fragment>
        )
    }
}

export default nartaRatingReviewsFilter;
