import React from "react";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/src/owl.carousel.css";
import { Link, Text, RichText, withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { NavLink } from 'react-router-dom';
import {getRelativePaths} from '../../utils/nartaUtils.js';
import "./nartaCarouselHome.scss";
import { handleSliderButtonClick } from '../Tagging';

class nartaCarouselHome extends React.Component {
  constructor(props) {
    
    super(props);

    this.state = {
      activeDot: 0
    };
  } 

/*  setActiveDot(dot) {
    this.setState({
      activeDot: dot
    });
  }*/

  shouldComponentUpdate(nextProps, nextState) {
		return false;
	}


  render() {
    let options = {
      items: 1,  
      loop: true,
      autoplay:false,
      autoplayTimeout:3000,
      autoplayHoverPause:true,
      nav: true,
      navText: [
        `<div class="tp-leftarrow tparrows custom  noSwipe"
      style="top: 50%; transform: matrix(1, 0, 0, 1, 30, -20); left: 0px; opacity: 0; transition:all 0.5s ease;"></div>`,
        `<div class="tp-rightarrow tparrows custom  noSwipe"
      style="top: 50%; transform: matrix(1, 0, 0, 1, -70, -20); left: 100%; opacity: 0; transition:all 0.5s ease;"></div>`
      ],
      dots: true
      // autoplay: true,
      // autoplayTimeout: 10000,
      // autoplayHoverPause: true,
      // animateIn: //class for css3 animation,
      // animateOut: //class for css3 animation,
    };
    console.log(this.props.sitecoreContext);

    const pageCateg = this.props.sitecoreContext.route.fields.pageCategoryGTM.value

    const events = {
      onChange: function() {
        handleSliderButtonClick(pageCateg, "carousel home")
      }
    }

    const slidesList = this.props.fields.Slides;

    let dots = [];
    for (let i = 0; i <= slidesList.length; i++) {
      dots.push(
        <div
          className={"owl-dot" + this.state.activeDot === i ? "selected" : ""}
          key={i}
         ></div>
      );
    }

    const designVariant =
      this.props.fields.designVariant === undefined
        ? ""
        : this.props.fields.designVariant &&
          this.props.fields.designVariant.value;
    return (
      <div className={`section ${designVariant}`}>
      <h1 style={{height: "0", overflow: "hidden"}}>Les déodorants NARTA, made in France</h1>
        <div className="container-fluid home-slider-container">
          <div className="row homeCarousel">
            <OwlCarousel options={options} events={events}>
              {slidesList &&
                slidesList.map((listItem, index) => (
                  <li key={`listItem-${index}`}>
                    <div
                      className="banner"
                      style={{
                        backgroundImage:
                          `url(` + listItem.fields.Banner.value.src + `)`
                      }}>
                      <div className="text-wrapper">
                        <div className="text-blue">
                          <div className="tp-caption business_small_white tp-resizeme">
                            <Text field={listItem.fields.Subtitle} />
                          </div>
                        </div>
                        <div>
                          <div className="tp-caption business_big_white tp-resizeme">
                            <RichText field={listItem.fields.Title} />
                          </div>
                        </div>
                        <div className="button-wrapper">
                          {listItem.fields.ButtonLeft &&
                            listItem.fields.ButtonLeft.value.href !== "" && (
                              <div className="button-wrapper__link-container">
                                <NavLink className="btn btn-white-outline btn--transparent" to={getRelativePaths(listItem.fields.ButtonLeft.value.href)}>{listItem.fields.ButtonLeft.value.text}</NavLink>
                                {/* <Link
                                  field={listItem.fields.ButtonLeft}
                                  className="btn btn-white-outline btn--transparent"
                                /> */}
                              </div>
                            )}
                          {listItem.fields.ButtonRight &&
                            listItem.fields.ButtonRight.value.href !== "" && (
                              <div>
                                <NavLink className="btn btn-white-outline btn--transparent" to={getRelativePaths(listItem.fields.ButtonRight.value.href)}>{listItem.fields.ButtonRight.value.text}</NavLink>
{/*                                 
                                <Link
                                  field={listItem.fields.ButtonRight}
                                  className="btn btn-white-outline btn--transparent"></Link> */}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(nartaCarouselHome);
