import React from 'react';
// import "../../assets/css/customSearch.css";
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import NartaQueryBuilder from '../../utils/nartaQueryBuilder.js';
import { NavLink } from 'react-router-dom';
import { getRelativePaths } from '../../utils/nartaUtils.js';
import './nartaGlobalSearch.scss';
import ReactComponent from '../../ReactComponent';
import Translate from '../../utils/TranslationRoute';
import { handleInternalSearch, productClickTagging } from '../Tagging';
//import history from '../../history';

class nartaGlobalSearch extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      itemdata: [],
      tempKeyword: '',
    };
    this.onItemsChange = this.onItemsChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  onItemsChange(itemdata) {
    this.setState({ itemdata: itemdata });
  }

  componentDidMount() {
    const url = window.location.href;
    let keyword = '';
    if (url.includes('?search=')) {
      keyword = url.substring(url.indexOf('=') + 1);

      if (keyword.includes('#')) keyword = keyword.replace('#', '');
      this.setState({
        keyword: decodeURIComponent(keyword),
        tempKeyword: decodeURIComponent(keyword),
      });

      //GTM
      setTimeout(function () {
        handleInternalSearch(decodeURIComponent(keyword));
      }, 500);
    }
  }

  handleClick() {
    this.setState({
      keyword: this.state.tempKeyword,
    });
  }

  handleClose() {
    this.setState({
      tempKeyword: '',
    });
  }

  handleChange(e) {
    this.setState({
      tempKeyword: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const params = new URLSearchParams(window.location.search);
    params.set('search', this.state.tempKeyword);
    //history.push(history.location.pathname+"?"+params.toString())
    this.setState({
      keyword: this.state.tempKeyword,
    });

    //GTM
    handleInternalSearch(this.state.tempKeyword);
  }

  render() {
    const { t } = this.props;
    var INDEX = this.indexName;
    var SITE_ROOTPATH = '/sitecore/content/' + this.appName;
    var QUERY =
      'query={ search: customSearch( index:"' +
      INDEX +
      '" rootItem: "' +
      SITE_ROOTPATH +
      '", conditions: [ {name: "_templatename", value: "PostRoute", useor:true}, {name: "_templatename", value: "NartaProductRoute", useor:true} ] keyword: "##keyword") { results { totalCount pageInfo { hasNextPage startCursor endCursor hasPreviousPage } items { path templateName item { id url name ... on PostRoute { postTitle { value } postThumbnailImageMedium { src alt } postShortDescription { value } } ... on NartaProductRoute { title { value } codeEan { value } image1 { src alt } image2 { src alt } rating { numberValue } productShortDescription { value } } date: field(name: "__Updated") { value } } } } } } ';
    const { keyword, itemdata } = this.state;

    let empty = null;
    if (itemdata.data && itemdata.data.search.results.items.length === 0) {
      empty = <div>Nous n'avons trouvé aucun produit correspondant à votre recherche</div>;
    }
    const designVariant =
      this.props.fields.designVariant === undefined ? '' : this.props.fields.designVariant && this.props.fields.designVariant.value;
    return (
      <div className={`${designVariant}`}>
        <div className="heading-container">
          <div
            className="heading-background"
            style={{
              backgroundImage: `url(` + this.props.fields.backgroundImage.value.src + `)`,
            }}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading-wrap">
                    <div className="page-title">
                      <Text tag="h1" field={this.props.fields.title} />
                    </div>
                    <div className="form-wrapper">
                      <form className="searchBar" onSubmit={this.handleSubmit}>
                        <div className="search-wrapper">
                          <input type="search" placeholder="VOTRE RECHERCHE..." value={this.state.tempKeyword} onChange={this.handleChange} />
                          <input type="submit" id="globalSearchSubmit" className="hidden" name="submit" value="Search"></input>
                          <i className="fa fa-search" onClick={this.handleClick}></i>
                          <i onClick={this.handleClose} className={this.state.tempKeyword === '' ? 'hidden' : 'fa fa-close'}></i>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 main-wrap">
              <div className="main-content">
                <div className="posts" data-layout="grid">
                  <div className="search-grid">
                    {itemdata.data && empty && empty}
                    {itemdata.data &&
                      itemdata.data.search.results.items &&
                      itemdata.data.search.results.items.map((result, index) => {
                        if (result.item.postTitle)
                          return (
                            <div key={index} className=" col-md-4 col-sm-6 hentry">
                              <div className="hentry-wrap">
                                <div className="entry-featured">
                                  <NavLink to={getRelativePaths(result.item.url)}>
                                    <img src={result.item.postThumbnailImageMedium.src} alt={result.item.postThumbnailImageMedium.alt} />
                                  </NavLink>
                                </div>
                                <div className="entry-info">
                                  <div className="entry-header">
                                    <h2 className="entry-title">
                                      <NavLink to={getRelativePaths(result.item.url)}>{result.item.postTitle.value}</NavLink>
                                    </h2>
                                  </div>
                                  <div className="entry-content">{result.item.postShortDescription.value}</div>
                                  <div className="clearfix">
                                    <div className="readmore-link">
                                      <NavLink to={getRelativePaths(result.item.url)}>Read More</NavLink>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        else if (result.item.title)
                          return (
                            <div
                              key={index}
                              data-gtm-name={result.item.title.value}
                              data-gtm-ean={result.item.codeEan.value}
                              data-gtm-category={result.item.url.split('/')[2]}
                              data-gtm-rating={result.item.rating.numberValue}
                              data-gtm-list="list-result-search"
                              data-gtm-position={index + 1}
                              className="col-md-4 col-sm-6 hentry product-container">
                              <div className="hentry-wrap">
                                <div className="entry-featured">
                                  <NavLink
                                    onClick={() =>
                                      productClickTagging(
                                        result.item.title.value,
                                        result.item.codeEan.value,
                                        result.item.url.split('/')[2],
                                        result.item.rating.numberValue,
                                        'list-result-search',
                                        index + 1
                                      )
                                    }
                                    to={getRelativePaths(result.item.url)}>
                                    <img src={result.item.image1.src} alt={result.item.image1.alt} />
                                  </NavLink>
                                </div>
                                <div className="entry-info">
                                  <div className="entry-header">
                                    <h2 className="entry-title">
                                      <NavLink
                                        onClick={() =>
                                          productClickTagging(
                                            result.item.title.value,
                                            result.item.codeEan.value,
                                            result.item.url.split('/')[2],
                                            result.item.rating.numberValue,
                                            'list-result-search',
                                            index + 1
                                          )
                                        }
                                        to={getRelativePaths(result.item.url)}>
                                        {result.item.title.value}
                                      </NavLink>
                                    </h2>
                                  </div>
                                  <div className="entry-content">{result.item.productShortDescription.value}</div>
                                  <div className="clearfix">
                                    <div className="readmore-link">
                                      <NavLink
                                        onClick={() =>
                                          productClickTagging(
                                            result.item.title.value,
                                            result.item.codeEan.value,
                                            result.item.url.split('/')[2],
                                            result.item.rating.numberValue,
                                            'list-result-search',
                                            index + 1
                                          )
                                        }
                                        to={getRelativePaths(result.item.url)}>
                                        <Translate TranslationKey="product-view-product" />
                                      </NavLink>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        return false;
                      })}
                    <NartaQueryBuilder
                      onItemsChange={this.onItemsChange}
                      query={QUERY}
                      itemsPerPage={this.props.fields.itemsPerPage.value}
                      keyword={keyword}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(nartaGlobalSearch);
