import React from "react";
import { Text, Image } from "@sitecore-jss/sitecore-jss-react";
import StarRating from "../nartaStarRating";
import { loader as gqlLoader } from "graphql.macro";
import GraphQLData from "../../lib/GraphQLData";
import ProductReviews from "../../utils/ProductReviews/index.js";
import { NavLink } from "react-router-dom";
import { getRelativePaths } from "../../utils/nartaUtils.js";
import "./nartaBestRatedProducts.scss";
import { productClickTagging } from "../Tagging";
const BestRatedProductsQuery = gqlLoader("./BestRatedProductsQuery.graphql");


const nartaBestRatedProducts = props => {
  const designVariant =
    props.fields.designVariant === undefined
      ? ""
      : props.fields.designVariant && props.fields.designVariant.value;
  const graphQLResult = props.bestRatedProductsQuery;
  const { datasource } = graphQLResult;
  return (
    <div className={`container margin_custom ${designVariant}`}>
      <div className="related__product__custom home-bestsellers">
        <div className="col-md-12 pad">
          <Text field={props.fields.BestRatedProductsHeading} tag="h2" />
        </div>
        {datasource &&
          datasource.bestRatedProductsList.targetItems.map(
            (productItem, index) => (
              <div data-gtm-name={productItem.title.jss.value}
              data-gtm-ean={productItem.codeEan.value}
              data-gtm-category={productItem.url.split('/')[2]}
              data-gtm-rating={productItem.rating.numberValue}
              data-gtm-list="highlight-product"
              data-gtm-position={index + 1}
              onClick={() => productClickTagging(productItem.title.jss.value, productItem.codeEan.value, productItem.url.split('/')[2], productItem.rating.numberValue,
                'highlight-product',
                index + 1)}
              className="portfolio-item text-center product-container" key={index}>
                <NavLink
                  className="portfolio-link"
                  to={getRelativePaths(productItem.url)}>
                  <img src={productItem.image1 && productItem.image1.src} className="img-fluid" />

                  <div className="portfolio-caption min_font">
                    <Text field={productItem.title.jss} tag="h4" />
                    <Text field={productItem.beneficeProduit.jss} />
                    <div className="portfolio-rating">
                      <StarRating value={productItem.rating.numberValue} />
                      <ProductReviews productId={productItem.id} />
                    </div>
                  </div>
                </NavLink>
                <a
                  data-eans={productItem.codeEan.value}
                  className="swn-tag-wtb-btn btn btn_custom_oro">
                  Acheter
                </a>
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default GraphQLData(BestRatedProductsQuery, {
  name: "bestRatedProductsQuery"
})(nartaBestRatedProducts);
