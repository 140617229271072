import React from 'react';
import { Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import GraphQLData from '../../lib/GraphQLData';
import $ from 'jquery/dist/jquery';
import base64 from 'react-native-base64';
import { getRelativePaths } from '../../utils/nartaUtils.js';
import './nartaHorizontalMenu.scss';

import ReactComponent from '../../ReactComponent';
import { loader as gqlLoader } from 'graphql.macro';
import { Query } from 'react-apollo';
import { DotLoader } from 'react-spinners';
import { css } from '@emotion/core';
import { handleNavigationClick, handleLogoClick } from '../Tagging';

const override = css`
  margin: auto;
`;

const RootPathQuery = gqlLoader('./query.graphql');
const nartaHorizontalMenuQuery = gqlLoader('./nartaHorizontalMenu.graphql');

let loadCount = 0;
class nartaHorizontalMenu extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isMounted: false,
      itemsForRootpath: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /*    componentDidUpdate(prevProps, nextProps){
    
    	const locationChanged = 
    		this.props.sitecoreContext.route.fields.pageTitle.value !== prevProps.sitecoreContext.route.fields.pageTitle.value

    	console.log("this", this.props.sitecoreContext.route.fields.pageTitle.value);
    	console.log("prevProps", prevProps.sitecoreContext.route.fields.pageTitle.value);
    	if(nextProps){

    	console.log("nextProps", nextProps);
    	console.log("isMounted", this.state.isMounted);
    	}
    }*/

  componentDidMount() {
    // var API_ENDPOINT = configGraphQLSC.sitecoreMasterGraphQLEndpoint;

    // //var INDEX = configGraphQLSC.sitecoreIndex;
    // var SITE_ROOTPATH = configGraphQLSC.siteRootPath;
    // var ROOTITEM = SITE_ROOTPATH + "/home"
    // var QUERY =  'query=query{ item(path: "' + ROOTITEM+'") { url } }';

    // fetch(API_ENDPOINT + QUERY)
    //   .then(res => res.json())
    //   .then(result => {

    //     this.setState({
    //       itemsForRootpath: result
    //     });
    //   });

    this.superfishInit();
    $(window).on('resize', this.superfishInit);
    $(window).scroll(function () {
      //sticky navbar
      var e = window,
        a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
      }
      var width = e[a + 'Width'];
      if (width > 319) {
        if ($(this).scrollTop() > 100) {
          $('.navbar-default').addClass('navbar-fixed-top');
          $('.navbar-default').addClass('fixed-transition');
          // $(".header-container").addClass("header-navbar-fixed");
        } else {
          $('.navbar-default').removeClass('navbar-fixed-top');
          $('.navbar-default').removeClass('fixed-transition');
          // $(".header-container").removeClass("header-navbar-fixed");
        }
      }
    });

    $(document).on('click', '.navbar-search-button', function (e) {
      e.stopPropagation();
      e.preventDefault();
      if ($('.header-search-overlay').length) {
        $('.header-search-overlay')
          .stop(true, true)
          .removeClass('hide')
          .css('opacity', 0)
          .animate(
            {
              opacity: 1,
            },
            600,
            'swing',
            function () {
              $(this).find('.searchinput').focus();
            }
          );
      } else if ($('.search-form-wrap').length) {
        if ($('.search-form-wrap').hasClass('hide')) {
          $('.search-form-wrap').removeClass('hide').addClass('show');
          $('.search-form-wrap .searchinput').focus();
        }
      }
    });

    $(document).on('click', '.header-search-overlay .close', function () {
      $('.header-search-overlay')
        .stop(true, true)
        .animate(
          {
            opacity: 0,
          },
          600,
          'swing',
          function () {
            $(this).addClass('hide');
          }
        );
    });

    this.setState({
      isMounted: true,
    });

    //Off Canvas menu
    $('.navbar-toggle-fixed-btn,.navbar-toggle,.navbar-offcanvas-btn').on('click', function (e) {
      e.stopPropagation();
      e.preventDefault();
      if ($('body').hasClass('open-offcanvas')) {
        $('body').removeClass('open-offcanvas').addClass('close-offcanvas');
        $('.navbar-toggle').removeClass('x');
      } else {
        $('body').removeClass('close-offcanvas').addClass('open-offcanvas');
        $('.navbar-toggle').addClass('x');
      }
    });

    $('body').on(
      'mousedown',
      $.proxy(function (e) {
        var element = $(e.target);
        if ($('.offcanvas').length && $('body').hasClass('open-offcanvas')) {
          if (
            !element.is('.offcanvas') &&
            element.parents('.offcanvas').length === 0 &&
            !element.is('.navbar-toggle') &&
            element.parents('.navbar-toggle').length === 0 &&
            !element.is('.navbar-offcanvas-btn') &&
            element.parents('.navbar-offcanvas-btn').length === 0
          ) {
            $('body').removeClass('open-offcanvas');
            $('.navbar-toggle').removeClass('x');
          }
        }
      }, this)
    );

    $('.offcanvas-nav .dropdown-hover .caret,.offcanvas-nav .dropdown-submenu > a > .caret,.offcanvas-nav .megamenu-title .caret')
      .off('click')
      .on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        var dropdown = $(this).closest('.dropdown, .dropdown-submenu');
        if (dropdown.hasClass('open')) {
          dropdown.removeClass('open');
        } else {
          dropdown.addClass('open');
        }
      });
    function ScrollToAnchor() {
      const url = window.location.href;
      if (url.includes('#')) {
        let hashValue = url.substring(url.indexOf('#') + 1);
        let element = document.getElementById(hashValue);
        if (element) {
          let headerOffset = 90;
          let elementPosition = element.getBoundingClientRect().top;
          let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
      return null;
    }

    ScrollToAnchor();
  }

  superfishInit() {
    if ($ !== undefined && window.$.fn.superfish !== undefined) {
      $.fn.superfish = window.$.fn.superfish;
      $('.primary-nav').superfish({
        anchorClass: '.dropdown', // selector within menu context to define the submenu element to be revealed
        hoverClass: 'open', // the class applied to hovered list items
        pathClass: 'overideThisToUse', // the class you have applied to list items that lead to the current page
        pathLevels: 1, // the number of levels of submenus that remain open or are restored using pathClass
        delay: 650, // the delay in milliseconds that the mouse can remain outside a submenu without it closing
        animation: {
          opacity: 'show',
        }, // an object equivalent to first parameter of jQuery’s .animate() method. Used to animate the submenu open
        animationOut: {
          opacity: 'hide',
        }, // an object equivalent to first parameter of jQuery’s .animate() method Used to animate the submenu closed
        speed: 'fast', // speed of the opening animation. Equivalent to second parameter of jQuery’s .animate() method
        speedOut: 'fast', // speed of the closing animation. Equivalent to second parameter of jQuery’s .animate() method
        cssArrows: true, // set to false if you want to remove the CSS-based arrow triangles
        disableHI: false, // set to true to disable hoverIntent detection
      });
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    window.location.href = '/search?search=' + this.state.value;
    event.preventDefault();
  }

  handleLogout() {
    sessionStorage.removeItem('loggedUser');
  }

  render() {
    let homeUrl =
      this.state.itemsForRootpath &&
      this.state.itemsForRootpath.data &&
      this.state.itemsForRootpath.data.item &&
      this.state.itemsForRootpath.data.item.url;

    const graphQLResult = this.props.MenuQuery;
    const { datasource } = graphQLResult;

    let user = null;
    if (datasource && datasource.isLoginEnabled && this.state.isMounted && datasource.isLoginEnabled.value === '1') {
      if (sessionStorage.getItem('loggedUser')) {
        let sessionChecker = sessionStorage.getItem('loggedUser');
        let getSession = base64.decode(sessionChecker);
        user = getSession;
      }
    }

    let headerClass = 'header-container header-type-classic header-scroll-resize';

    const designVariant =
      this.props.fields.designVariant === undefined ? '' : this.props.fields.designVariant && this.props.fields.designVariant.value;
    return (
      <div className={`${designVariant}`}>
        <div className="header-offcanvas navbar-offcanvas offcanvas open">
          {datasource && (
            <div className="offcanvas-wrap">
              <div className="navbar-toggle-fixed">
                <button type="button" className="navbar-toggle x">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar bar-top"></span>
                  <span className="icon-bar bar-middle"></span>
                  <span className="icon-bar bar-bottom"></span>
                </button>
              </div>
              <div className="offcanvas-user clearfix">
                {user && datasource && datasource.isLoginEnabled && datasource.isLoginEnabled.value === '1' ? (
                  <div className="primary-nav">{user}&nbsp;&nbsp;</div>
                ) : (
                  ''
                )}
                {user && datasource && datasource.isLoginEnabled && datasource.isLoginEnabled.value === '1' ? (
                  <a className="offcanvas-user-account-link" href="#" onClick={this.handleLogout.bind(this)}>
                    <i className="fa fa-user"></i> LOGOUT
                  </a>
                ) : datasource && datasource.isLoginEnabled && datasource.isLoginEnabled.value === '1' ? (
                  <NavLink className="offcanvas-user-account-link" to="/Login">
                    <i className="fa fa-user"></i> LOGIN / REGISTER
                  </NavLink>
                ) : (
                  ''
                )}
              </div>
              <nav className="offcanvas-navbar mobile-offcanvas-navbar">
                <ul className="offcanvas-nav">
                  {datasource &&
                    datasource.children.map((listItem, index) => (
                      <li key={`sharedListItem-${index}`} className="menu-item-has-children dropdown">
                        <NavLink
                          className="dropdown-hover"
                          onClick={() => handleNavigationClick('burger menu', listItem.name)}
                          to={getRelativePaths(listItem.internalLink.url)}>
                          <span className="underline">{listItem.name}&nbsp;</span>
                          {listItem.children.length > 0 && <span className="caret"></span>}
                        </NavLink>
                        {listItem.children.length > 0 && (
                          <ul className="dropdown-menu">
                            {listItem.children.map((subListItem, index) => (
                              <li key={`sharedSubListItem-${index}`} className="menu-item">
                                <NavLink
                                  onClick={() => handleNavigationClick('burger menu', listItem.name+"::"+subListItem.name)}
                                  to={getRelativePaths(subListItem.internalLink.url)}>
                                  {subListItem.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                </ul>
              </nav>

              <div className="offcanvas-widget hide-iphone">
                <div className="widget widget_search">
                  <h4 className="widget-title">
                    <span>Recherche</span>
                  </h4>
                  <form onSubmit={this.handleSubmit}>
                    <label htmlFor="s" className="sr-only">
                      Recherche
                    </label>
                    <input
                      type="search"
                      id="s"
                      name="s"
                      className="form-control"
                      value={this.state.value}
                      placeholder="Rechercher..."
                      onChange={this.handleChange}
                    />
                    <input type="submit" id="searchsubmit" className="hidden" name="submit" value="Search" />
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="offcanvas-overlay"></div>
        <header id="header" className={headerClass}>
          {datasource && (
            <div className="navbar-container">
              <div className="navbar navbar-default navbar-scroll-fixed">
                <div className="navbar-default-container">
                  <div className="navbar-default-wrap">
                    <div className="container">
                      <div className="row">
                        <div className="navbar-default-col">
                          <div className="navbar-wrap">
                            <div className="navbar-header">
                              <button type="button" className="navbar-toggle">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar bar-top"></span>
                                <span className="icon-bar bar-middle"></span>
                                <span className="icon-bar bar-bottom"></span>
                              </button>
                              <a className="navbar-search-button search-icon-mobile" href="#">
                                <svg
                                  xmlSpace="preserve"
                                  viewBox="0 0 612 792"
                                  y="0px"
                                  x="0px"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1">
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          d="M231,104c125.912,0,228,102.759,228,229.5c0,53.034-18.029,101.707-48.051,140.568l191.689,192.953
																		c5.566,5.604,8.361,12.928,8.361,20.291c0,7.344-2.795,14.688-8.361,20.291C597.091,713.208,589.798,716,582.5,716
																		s-14.593-2.792-20.139-8.396L370.649,514.632C332.043,544.851,283.687,563,231,563C105.088,563,3,460.241,3,333.5
																		S105.088,104,231,104z M231,505.625c94.295,0,171-77.208,171-172.125s-76.705-172.125-171-172.125
																		c-94.295,0-171,77.208-171,172.125S136.705,505.625,231,505.625z"
                                        />
                                      </g>
                                    </g>
                                  </g>
                                </svg>
                              </a>
                              <Query query={RootPathQuery} variables={{ rootItem: '/sitecore/content/' + this.appName + '/home' }}>
                                {({ loading, error, data }) => {
                                  if (loading)
                                    return (
                                      <div className="sweet-loading" style={{ marginTop: '50px', marginBottom: '50px' }}>
                                        <DotLoader css={override} sizeUnit={'px'} size={50} color={'#0a4f91'} loading={!this.state.isLoaded} />
                                      </div>
                                    );

                                  if (error) return <div>Error: {error.message}</div>;
                                  let homeUrl = data && data.item && data.item.url;
                                  return (
                                    <NavLink onClick={() => handleLogoClick()} className="navbar-brand" to={homeUrl}>
                                      {headerClass ===
                                      'header-container header-type-classic header-absolute header-transparent header-scroll-resize' ? (
                                        <img
                                          src={datasource.sitelogoTransparent && datasource.sitelogoTransparent.src}
                                          className="logo"
                                          alt="Narta"
                                        />
                                      ) : (
                                        <img src={datasource.sitelogo && datasource.sitelogo.src} className="logo" alt="Narta" />
                                      )}
                                      <img src={datasource.sitelogoFixed && datasource.sitelogoFixed.src} className="logo-fixed" alt="Narta" />
                                      <img src={datasource.sitelogoMobile && datasource.sitelogoMobile.src} className="logo-mobile" alt="Narta" />
                                    </NavLink>
                                  );
                                }}
                              </Query>
                            </div>
                            <nav className="collapse navbar-collapse primary-navbar-collapse">
                              <ul className="nav navbar-nav primary-nav">
                                {datasource.children.map((listItem, index) => (
                                  <li key={`sharedListItem-${index}`} className="menu-item-has-children dropdown">
                                    <NavLink
                                      className="dropdown-hover"
                                      onClick={() => handleNavigationClick('header', listItem.name)}
                                      activeClassName={'is-active'}
                                      to={getRelativePaths(listItem.internalLink.url)}>
                                      <div className="nav__item">
                                        {listItem.name}&nbsp;
                                        {listItem.children.length > 0 && <span className="caret"></span>}
                                      </div>
                                    </NavLink>
                                    {listItem.children.length > 0 && (
                                      <ul className="dropdown-menu">
                                        {listItem.children.map((subListItem, index) => (
                                          <li key={`sharedSubListItem-${index}`} className="menu-item">
                                            <NavLink onClick={() => handleNavigationClick('header', listItem.name+"::"+subListItem.name)} to={getRelativePaths(subListItem.internalLink.url)}>{subListItem.name}</NavLink>
                                          </li>
                                        ))}
                                      </ul>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </nav>
                            <div className="header-right">
                              {user && datasource.isLoginEnabled && datasource.isLoginEnabled.value === '1' ? (
                                <div className="primary-nav">{user}&nbsp;&nbsp;</div>
                              ) : (
                                ''
                              )}
                              {user && datasource.isLoginEnabled && datasource.isLoginEnabled.value === '1' ? (
                                <a className="primary-nav" href="#" onClick={this.handleLogout.bind(this)}>
                                  LOGOUT
                                </a>
                              ) : datasource.isLoginEnabled && datasource.isLoginEnabled.value === '1' ? (
                                <a className="primary-nav" href="/Login">
                                  LOGIN / REGISTER
                                </a>
                              ) : (
                                ''
                              )}
                              <div className="navbar-search">
                                <a className="navbar-search-button" href="#">
                                  <svg
                                    xmlSpace="preserve"
                                    viewBox="0 0 612 792"
                                    y="0px"
                                    x="0px"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1">
                                    <g>
                                      <g>
                                        <g>
                                          <path
                                            d="M231,104c125.912,0,228,102.759,228,229.5c0,53.034-18.029,101.707-48.051,140.568l191.689,192.953
                                      c5.566,5.604,8.361,12.928,8.361,20.291c0,7.344-2.795,14.688-8.361,20.291C597.091,713.208,589.798,716,582.5,716
                                      s-14.593-2.792-20.139-8.396L370.649,514.632C332.043,544.851,283.687,563,231,563C105.088,563,3,460.241,3,333.5
                                      S105.088,104,231,104z M231,505.625c94.295,0,171-77.208,171-172.125s-76.705-172.125-171-172.125
                                      c-94.295,0-171,77.208-171,172.125S136.705,505.625,231,505.625z"
                                          />
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="header-search-overlay hide">
                  <div className="container">
                    <div className="header-search-overlay-wrap">
                      <form className="searchform" onSubmit={this.handleSubmit}>
                        <input
                          type="search"
                          className="searchinput"
                          name="s"
                          value={this.state.value}
                          autoComplete="off"
                          placeholder="Recherche..."
                          onChange={this.handleChange}
                        />
                        <input type="submit" className="searchsubmit hidden" name="submit" value="Search" />
                      </form>
                      <button type="button" className="close">
                        <span aria-hidden="true" className="fa fa-times"></span>
                        <span className="sr-only">Close</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </header>
      </div>
    );
  }
}
export default withSitecoreContext()(GraphQLData(nartaHorizontalMenuQuery, { name: 'MenuQuery' })(nartaHorizontalMenu));
