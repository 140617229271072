import React from "react";
import { Text } from "@sitecore-jss/sitecore-jss-react";
import "./nartaHeadingBanner.scss";
const nartaHeadingBanner = props => {
  
  const { designVariant } = props.fields;
  return (
    <div
      className={`heading-background bg-2 ${designVariant && designVariant != undefined &&
        designVariant.value}`}
      style={{
        backgroundImage: `url(` + props.fields.backgroundImage.value.src + `)`
      }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-wrap">
              <div className="page-title">
                <Text field={props.fields.title} tag="h1" />
                <span className="subtitle">
                  <Text field={props.fields.subtitle} />{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default nartaHeadingBanner;
