import React from 'react';
import { withTranslation } from 'react-i18next';

class TranslationRoute extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <React.Fragment>
                {this.props.t(this.props.TranslationKey)}
            </React.Fragment>
        )
    }
}

export default withTranslation()(TranslationRoute);