import React from "react";
import { Placeholder } from "@sitecore-jss/sitecore-jss-react";
import "./nartaPostLayoutWrapper.scss";
const nartaPostLayoutWrapper = props => {
  
  return (
    <div class="container single-blog__content">
      <div class="rowd">
        <div class="main-wrap">
          <div class="main-content">
            <Placeholder name="main-content" rendering={props.rendering} />
          </div>
        </div>
        <div class="sidebar-wrap">
          <div class="main-sidebar no-border">
            <Placeholder name="sidebar-right" rendering={props.rendering} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default nartaPostLayoutWrapper;
